import React from 'react';

class Intro extends React.Component {
  render() {
    return (
      <React.Fragment>
          <div id="hero">
            <div className="container">
            <h1 className="section-title">Michelle Falzon</h1>
            </div>
            <div className="slogan">
              <div className="container">
                Hard work is the bridge between goals and accomplishment          
              </div>
            </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Intro;